import Cookies from 'js-cookie';
import React, { useContext, useEffect, useState } from 'react'
import { Link, Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useTranslation } from 'react-i18next';
import { LocalizationContext } from '../../../../Context/LocalizationContext';

export default function OutPatientSurvey() {
    const { pathname } = useLocation();
    // translation 
    const { t } = useTranslation('OPSurvey');
    let { i18n, handleLanguageChange } = useContext(LocalizationContext)
    // translation 
    const navigate = useNavigate();

    const changeLanguage = (lang, dir) => {
        // i18n.changeLanguage(lang, dir);
        try {
            i18n.changeLanguage(lang);
            Cookies.set('i18next', lang, { expires: 365 });
            console.log('Language set to:', lang, dir);
            document.getElementsByTagName("html")[0].setAttribute("lang", lang);
            document.getElementsByTagName("html")[0].setAttribute("dir", dir);
        } catch (error) {
            console.error('Error setting language:', error);
        }
        // Cookies.remove('i18next');

        // Cookies.set('i18next', lang);
        // document.getElementsByTagName("html")[0].setAttribute("lang", lang);
        // document.getElementsByTagName("html")[0].setAttribute("dir", dir);
        // Cookies.set('i18next', lang);
    }
    // Populate hidden fields on component mount
    const { id } = useParams();
    const { code } = useParams();
    let [doctorName_en, setDoctorName_en] = useState();
    let [doctorName_ar, setDoctorName_ar] = useState();
    let [serviceDate, setServiceDate] = useState();
    // check if link available to open or expired else ...
    async function checkAvailability() {

        const bodyFormData = new FormData();
        bodyFormData.append('id', id);
        bodyFormData.append('code', code);
        const headers = {
            'lang': Cookies.get('i18next'),
            'fingerprint': localStorage.getItem('fingerPrint'),
        }
        try {
            const response = await axios.post(`https://my-health.sghcairo.net/sgh_api/portal/public/api/vaild_servey`, bodyFormData, { headers });
            if (response.data.Ecode === 200) {
                setDoctorName_ar(response.data.Edata.DoctorName_ar);
                setDoctorName_en(response.data.Edata.DoctorName_en);
                setServiceDate(response.data.Edata.ServiceDate)
            } else {
                window.location.href = `https://sgheg.com/sgh/#/sgh/${Cookies.get('i18next')}/patient-satisfaction-survey/op/message`

                // window.location.href = `https://sgheg.com/sgh/#/sgh/${Cookies.get('i18next')}/patient-satisfaction-survey/op-survey/${id}/${code}`

            }
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        checkAvailability()

    }, [])

    return <>
        <div className="surveysparrow-survey-container--classic-form welcome-page" style={{ backgroundImage: `url('/images/bg/survey.jpg')` }}>
            <div className="ss-fp-section surveysparrow-survey-form-wrapper--centered ss-survey-background d-flex fx-column fx-jc--center fx-ai--center">
                <div className="ss-fp-section__frame ss_classic_survey_intro_contents">
                    <div className="ss-fp-section__inner-frame">
                        <h3 className="ss-header-text--fluid ss-survey-heading--text ss-survey-font-family ss-survey-line-height--normal ss-survey-text-weight--semibold ss-survey-text-color--primary ss-survey-text-align--center ss-survey-text-question-text main-header-font-size--xl">

                            <figure>
                                <span>

                                    <div className="ss_image_wrapper">
                                        <img
                                            src="/images/logos/logo_sgh.png"
                                            alt="undefined"
                                        />
                                        <br />
                                        <img className='img-wrap'
                                            src="/images/logos/logo_hospital_egypt_copy-01-min (1).png"
                                            alt="undefined"
                                        />
                                    </div>
                                </span>
                            </figure>

                        </h3>
                        <p className="fw-bolder text-center ss-survey-heading--text ss-survey-question-description ss-survey-font-family ss-survey-text-size--lg sm_ss-survey-text-size--base ss-survey-line-height--normal ss-survey-text-weight--regular ss-survey-text-question-text ss-survey-text-color--primary-07">
                            Dear customer, please rate your experience during your Last visit to Saudi German Hospital with Dr. {doctorName_en}
                        </p>
                        <p className="fw-bolder text-center ss-survey-heading--text ss-survey-question-description ss-survey-font-family ss-survey-text-size--lg sm_ss-survey-text-size--base ss-survey-line-height--normal ss-survey-text-weight--regular ss-survey-text-question-text ss-survey-text-color--primary-07">
                            عميلنا العزيز من فضلك قم بتقييم تجربتك خلال زيارتك الاخيرة لمستشفى السعودي الألماني مع الدكتور {doctorName_ar}
                        </p>

                        <div className="ss_cl_qstn_action justify-content-center">

                            <button
                                id="welcome_cta_button"
                                data-qa="welcome_cta_button"
                                className="ss-primary-action-btn ss-survey-font-family ss-survey-text-size--base sm_ss-survey-text-size--base ss-survey-line-height--tight ss-survey-text-weight--bold ss-primary-action-btn--intro"
                                onClick={() => {
                                    handleLanguageChange('en')
                                    document.getElementsByTagName("html")[0].setAttribute("lang", 'en');

                                    document.getElementsByTagName("html")[0].setAttribute("dir", 'ltr');

                                    navigate(`/${Cookies.get('i18next')}/patient-satisfaction-survey/op-survey/${id}/${code}`);

                                }}
                            >
                                <Link to={`/${Cookies.get('i18next')}/patient-satisfaction-survey/op-survey/${id}/${code}`} className="text-white ss-primary-action-btn__copy">Start !</Link>
                                {/* <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 42 42"><g fill="currentColor"><path d="M9.841 42h-6.53a3.287 3.287 0 01-3.283-3.284V19.034A3.287 3.287 0 013.31 15.75h6.531a3.289 3.289 0 013.284 3.284v19.682A3.288 3.288 0 019.841 42zm-6.53-23.625a.659.659 0 00-.658.659v19.682a.66.66 0 00.657.659h6.531a.66.66 0 00.659-.659V19.034a.66.66 0 00-.659-.659h-6.53z"></path><path d="M33.898 42H17.99c-.81 0-1.62-.192-2.345-.554l-4.42-2.21a1.313 1.313 0 011.174-2.348l4.42 2.21a2.63 2.63 0 001.171.277h15.908c.479 0 .925-.259 1.164-.676l1.482-2.593a21.526 21.526 0 002.831-10.662v-4.441a2.63 2.63 0 00-2.628-2.628h-9.879a2.618 2.618 0 01-2.432-3.592 25.25 25.25 0 001.814-9.421V3.84c0-.669-.545-1.215-1.215-1.215h-.817c-.54 0-1.02.362-1.168.88l-.514 1.8A29.96 29.96 0 0117.1 15.833a6.735 6.735 0 01-5.288 2.542H7.874a1.313 1.313 0 010-2.625h3.938a4.129 4.129 0 003.239-1.558 27.35 27.35 0 004.96-9.609l.514-1.8A3.858 3.858 0 0124.218 0h.817a3.844 3.844 0 013.84 3.84v1.522c0 3.579-.675 7.077-2.002 10.396l9.874-.008A5.258 5.258 0 0142 21.003v4.441a24.15 24.15 0 01-3.178 11.965l-1.481 2.593A3.975 3.975 0 0133.898 42z"></path><circle cx="6.563" cy="35.438" r="1.313"></circle></g></svg> */}
                                <svg
                                    width="18"
                                    height="18"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M4 3L12 8L4 13"
                                        stroke="#63686F"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                </svg>
                            </button>

                            <button
                                id="welcome_cta_button"
                                data-qa="welcome_cta_button"
                                className="ss-primary-action-btn ss-survey-font-family ss-survey-text-size--base sm_ss-survey-text-size--base ss-survey-line-height--tight ss-survey-text-weight--bold ss-primary-action-btn--intro"
                                onClick={() => {
                                    handleLanguageChange('ar')
                                    document.getElementsByTagName("html")[0].setAttribute("lang", 'ar');

                                    document.getElementsByTagName("html")[0].setAttribute("dir", 'rtl');
                                    navigate(`/${Cookies.get('i18next')}/patient-satisfaction-survey/op-survey/${id}/${code}`);

                                }}
                            >
                                <svg
                                    width="18"
                                    height="18"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M12 3L4 8L12 13"
                                        stroke="#63686F"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                </svg>
                                {/* <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 42 42"><g fill="currentColor"><path d="M9.841 42h-6.53a3.287 3.287 0 01-3.283-3.284V19.034A3.287 3.287 0 013.31 15.75h6.531a3.289 3.289 0 013.284 3.284v19.682A3.288 3.288 0 019.841 42zm-6.53-23.625a.659.659 0 00-.658.659v19.682a.66.66 0 00.657.659h6.531a.66.66 0 00.659-.659V19.034a.66.66 0 00-.659-.659h-6.53z"></path><path d="M33.898 42H17.99c-.81 0-1.62-.192-2.345-.554l-4.42-2.21a1.313 1.313 0 011.174-2.348l4.42 2.21a2.63 2.63 0 001.171.277h15.908c.479 0 .925-.259 1.164-.676l1.482-2.593a21.526 21.526 0 002.831-10.662v-4.441a2.63 2.63 0 00-2.628-2.628h-9.879a2.618 2.618 0 01-2.432-3.592 25.25 25.25 0 001.814-9.421V3.84c0-.669-.545-1.215-1.215-1.215h-.817c-.54 0-1.02.362-1.168.88l-.514 1.8A29.96 29.96 0 0117.1 15.833a6.735 6.735 0 01-5.288 2.542H7.874a1.313 1.313 0 010-2.625h3.938a4.129 4.129 0 003.239-1.558 27.35 27.35 0 004.96-9.609l.514-1.8A3.858 3.858 0 0124.218 0h.817a3.844 3.844 0 013.84 3.84v1.522c0 3.579-.675 7.077-2.002 10.396l9.874-.008A5.258 5.258 0 0142 21.003v4.441a24.15 24.15 0 01-3.178 11.965l-1.481 2.593A3.975 3.975 0 0133.898 42z"></path><circle cx="6.563" cy="35.438" r="1.313"></circle></g></svg> */}

                                <Link to={`/${Cookies.get('i18next')}/patient-satisfaction-survey/op-survey/${id}/${code}`} className="text-white ss-primary-action-btn__copy">! ابــــــــــــــدأ</Link>

                            </button>
                        </div>
                    </div>
                </div>
            </div>



        </div >

    </>
}
