import React from 'react'
import { Link } from 'react-router-dom'
import Cookies from 'js-cookie';

export default function ShuttleBus() {
    return <>
        <div className="overflow-hidden" style={{

            backgroundImage: `url('/images/alex/shater-bus- (5).png')`, backgroundPosition: 'center',
            backgroundSize: 'contain', backgroundRepeat: 'no-repeat', paddingBottom: '80px',
            paddingTop: '70px', backgroundColor: 'gainsboro'
        }} >

        </div>
        <section class="fullscreen-banner p-0 overflow-hidden text-center" data-bg-img="/images/alex/background_result.webp" style={{ backgroundImage: 'url("/images/alex/background_result.webp")' }}>
            <div className="title mt-5 mx-3">
                <h5 style={{ color: '#434141' }}>الخدمة مجانية والحجز من خلال خدمة العملاء على الخط الساخن <a class="text-decoration-none fw-bolder fs-3" href="tel:16259">16259</a>
                </h5>
                <h5 style={{ color: '#434141' }}>في حال وجود صعوبة فى الوصول إلى المحطة المخصصة للباص برجاء التواصل على <a class="text-decoration-none fw-bolder fs-3" href="tel:01278216901">01278216901</a></h5>
            </div>

            <div class="container p-3">
                <div class="row">
                    <div class="col-md-12 mb-3">

                        <div class="box-shadow coming-soon">
                            <div class="block mb-3"><p className='lead fw-bolder fs-4 text-theme lh-base m-0'> سيدى جابر</p></div>

                            <div class="row justify-content-center px-md-7 px-2">
                                <div class="col-lg-4 col-md-12 p-0 white-bg text-muted border border-2 order-3 order-md-1" style={{ borderRadius: '1rem' }}>
                                    <h4 class="text-muted text-capitalize py-2" style={{ background: '#f8faf9' }}>العنوان</h4>
                                    <p className='px-3'>داخل جراج سيدى جابر خلف محطة قطار سيدى جابر وأمام برج الملتقى وعلى بعد خطوات من كارفور سيدى جابر وعلى بغد خطوات من عصير مكة ومطعم جاد</p>

                                    <Link to='https://maps.app.goo.gl/vUXaRxDZ6RD6kkwg7'> <button class="custom-btn btn-16 w-75 mb-3">الموقع على الخريطة</button></Link>
                                </div>

                                <div className="col-md-5 offset-md-3  row justify-content-between order-2 order-md-2">

                                    <div class="col-lg-4  col-md-12 p-0 white-bg text-muted border border-2 order-md-4 mb-3 mb-md-0" style={{ borderRadius: '1rem' }}>
                                        <h4 class="text-muted text-capitalize py-2" style={{ background: '#f8faf9' }}>التحرك</h4>
                                        <ul class="list-unstyled working-hours">

                                            <li className='text-center pb-2'>9:00 AM</li>
                                            <li className='text-center pb-2' style={{ background: '#f8faf9' }}>12:00 PM</li>
                                            <li className='text-center pb-2'> 8:00 PM</li>
                                            <li className='text-center pb-2' style={{ background: '#f8faf9' }}>11:00 PM</li>

                                        </ul>
                                    </div>
                                    <div class="col-lg-7 col-md-12 p-0 white-bg text-muted me-3 border border-2  order-md-2 mb-3 mb-md-0" style={{ borderRadius: '1rem' }}>
                                        <h4 class="text-muted text-capitalize py-2" style={{ background: '#f8faf9' }}>العودة من المستشفى</h4>
                                        <ul class="list-unstyled working-hours">
                                            <li className='text-center'>11:00 AM</li>

                                            <li className='text-center pb-2' style={{ background: '#f8faf9' }}>3:00 PM</li>
                                            <li className='text-center pb-2'> 10:00 PM</li>
                                            <li className='text-center pb-2' style={{ background: '#f8faf9' }}>1:00 AM</li>
                                        </ul>
                                    </div>
                                </div>




                            </div>

                        </div>
                    </div>

                    {/* <div class="col-md-12 mb-3">

                        <div class="box-shadow coming-soon">
                            <div class="block mb-3"><p className='lead fw-bolder fs-4 text-theme lh-base m-0'> محطة الرمل</p></div>

                            <div class="row justify-content-center px-md-7 px-2">
                                <div class="col-lg-4 col-md-12 p-0 white-bg text-muted border border-2 order-3 order-md-1" style={{ borderRadius: '1rem' }}>
                                    <h4 class="text-muted text-capitalize py-2" style={{ background: '#f8faf9' }}>العنوان</h4>
                                    <p className='px-3'>داخل جراج محطة الرمل على الجهة المقابلة لحلوانى ديليس بجوار موقف اتوبيسات العجمى</p>

                                    <Link to='https://maps.app.goo.gl/3Rhy8GLs9Lju5vwo8'> <button class="custom-btn btn-16 w-75 mb-3">الموقع على الخريطة</button></Link>
                                </div>
                                <div className="col-md-5 offset-md-3  row order-2 order-md-2">

                                    <div class="col-lg-4 col-md-12 p-0 white-bg text-muted border border-2 order-md-4 mb-3 mb-md-0" style={{ borderRadius: '1rem' }}>
                                        <h4 class="text-muted text-capitalize py-2" style={{ background: '#f8faf9' }}>التحرك</h4>
                                        <ul class="list-unstyled working-hours">

                                            <li className='text-center pb-2'>12:00 PM</li>
                                            <li className='text-center pb-2' style={{ background: '#f8faf9' }}> 3:00 PM</li>
                                            <li className='text-center pb-2'> 6:00 PM</li>
                                        </ul>
                                    </div>
                                    <div class="col-lg-7 col-md-12 p-0 white-bg text-muted me-3 border border-2 order-md-2 mb-3 mb-md-0" style={{ borderRadius: '1rem' }}>
                                        <h4 class="text-muted text-capitalize py-2" style={{ background: '#f8faf9' }}>العودة من المستشفى</h4>
                                        <ul class="list-unstyled working-hours">

                                            <li className='text-center pb-2' >2:00 PM</li>
                                            <li className='text-center pb-2' style={{ background: '#f8faf9' }}> 5:00 PM</li>
                                            <li className='text-center pb-2'> 8:00 PM</li>
                                        </ul>
                                    </div>
                                </div>



                            </div>

                        </div>
                    </div> */}
                    <div class="col-md-12 ">

                        <div class="box-shadow coming-soon">
                            <div class="block mb-3"><p className='lead fw-bolder fs-4 text-theme lh-base m-0'> الموقف الجديد</p></div>

                            <div class="row justify-content-center px-md-7 px-2">
                                <div className='col-lg-4 row flex-column order-3 order-md-1'>

                                    <div class="col-lg-12 col-md-12 p-0 white-bg text-muted border border-2 " style={{ borderRadius: '1rem' }}>
                                        <h4 class="text-muted text-capitalize py-2" style={{ background: '#f8faf9' }}>العنوان</h4>
                                        <p className='px-3'>خارج الموقف الجديد وامام بنزينة تشل اوت</p>

                                        <Link to='https://maps.app.goo.gl/oARYrUEwmmnbT5DL6'> <button class="custom-btn btn-16 w-75 mb-3">الموقع على الخريطة</button></Link>
                                    </div>
                                    {/* <div class="col-lg-12 col-md-12">
                                        <img src="/images/alex/shater-bus.png" className='w-100' alt="" srcset="" />
                                    </div> */}
                                </div>
                                <div className="col-md-5 offset-md-3  row order-2 order-md-2">

                                    <div class="col-lg-4  col-md-12 p-0 white-bg text-muted border border-2 order-md-4 mb-3 mb-md-0" style={{ borderRadius: '1rem' }}>
                                        <h4 class="text-muted text-capitalize py-2" style={{ background: '#f8faf9' }}>التحرك</h4>
                                        <ul class="list-unstyled working-hours">

                                            <li className='text-center pb-2'>9:30 AM</li>
                                            {/* <li className='text-center pb-2' style={{ background: '#f8faf9' }}> 12:30 PM</li> */}
                                            <li className='text-center pb-2' style={{ background: '#f8faf9' }}> 12:30 PM</li>
                                            {/* <li className='text-center pb-2' style={{ background: '#f8faf9' }}> 3:30 PM</li> */}
                                            <li className='text-center pb-2'> 8:30 PM</li>
                                            {/* <li className='text-center pb-2' style={{ background: '#f8faf9' }}> 6:30 PM</li> */}
                                            <li className='text-center pb-2' style={{ background: '#f8faf9' }}> 11:30 PM</li>
                                        </ul>
                                    </div>
                                    <div class="col-lg-7 col-md-12 p-0 white-bg text-muted me-3 border border-2 order-md-2 mb-3 mb-md-0" style={{ borderRadius: '1rem' }}>
                                        <h4 class="text-muted text-capitalize py-2" style={{ background: '#f8faf9' }}>العودة من المستشفى</h4>
                                        <ul class="list-unstyled working-hours">

                                            <li className='text-center pb-2'>11:00 AM</li>
                                            {/* <li className='text-center pb-2' style={{ background: '#f8faf9' }}>2:00 PM</li> */}
                                            <li className='text-center pb-2' style={{ background: '#f8faf9' }}>3:00 PM</li>
                                            {/* <li className='text-center pb-2' style={{ background: '#f8faf9' }} >5:00 PM</li> */}
                                            <li className='text-center pb-2'>10:00 PM</li>
                                            {/* <li className='text-center pb-2' style={{ background: '#f8faf9' }}> 8:00 PM</li> */}
                                            <li className='text-center pb-2' style={{ background: '#f8faf9' }}> 1:00 AM</li>
                                        </ul>
                                    </div>
                                </div>



                            </div>

                        </div>
                    </div>
                </div>
            </div>



        </section>

    </>
}
